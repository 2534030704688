import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule } from "@angular/forms";
import { QRCodeModule } from 'angularx-qrcode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingModule } from './setting/setting.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { HelpersModule } from './helpers/helpers.module';
import { DeveloperToolsModule } from './developer-tools/developer-tools.module';
import { AccountSecurityModule } from './account-security/account-security.module';
import { CentralService } from '@@Sf/central-service';
import { MasterDataModule } from './master-data/master-data.module';
import { YogiMgmtModule } from './yogi-mgmt/yogi-mgmt.module';
import { HospitalMgmtModule } from './hospital-mgmt/hospital-mgmt.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient,app: CentralService) {   
  //return new TranslateHttpLoader(http,'https://yogi.back.thabarwanaturecenter.net/storage/frontend/i18n/', '.json');   
  return new TranslateHttpLoader(http,'./assets/frontend/i18n/', '.json'); 
}

@NgModule({
  declarations: [
    AppComponent   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StorageServiceModule,
    NgxSpinnerModule,
    FormsModule,
    QRCodeModule,   
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,        
    DashboardModule,
    SettingModule,     
    HelpersModule, 
    DeveloperToolsModule, 
    NgbModule, 
    AccountSecurityModule,    
    MasterDataModule,
    YogiMgmtModule,
    HospitalMgmtModule    
  ],  
  providers: [    
    {provide: LocationStrategy, useClass: HashLocationStrategy}    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
